<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full p-10 text-left"
        >
          <div class="font-c8 text-c22">
            Code coupon
          </div>

            <div class="text-center text-c18 font-c4 mt-6">

              <div class="rounded-8 tete h-133 flex items-center" v-if="couponGenerate">
                <div class="w-full">
                  <div class="text-c14">
                    Code coupon
                  </div>

                  <div
                      class="flex justify-center items-center mt-2 pl-5 cursor-pointer"
                      v-clipboard:copy="couponGenerate.payementCode"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                  >
                    <div class="font-c8 text-c32">{{ couponGenerate.payementCode }}</div>
                    <icon
                        :data="icons.copy"
                        height="50"
                        width="50"
                        class=""
                        original
                    />
                  </div>
                </div>
              </div>

              <div class="w-full mt-6" v-if="couponGenerate">
                <buton
                    label="OK"
                    size="16px"
                    weight="600"
                    height="51px"
                    radius="5px"
                    background="#31B049"
                    @info="success"
                />
              </div>

            </div>

          <div v-if="!couponGenerate">
            <div
                class="text-c18 font-c4 mt-6 text-left"
            >
              <div class="text-83 text-c14 mb-2">Type de réduction</div>
              <multiselect :option="type" :value-t="valueType" height="46px" radius="8px" @info="backType"/>

              <div class="text-83 text-c14 mb-2 mt-4">Réduction</div>
              <inputo v-if="response.type === 'PERCENTAGE'" height="46px" type="number" radius="8px" :maxLength="3" @info="backReduction"/>
              <inputo v-if="response.type !== 'PERCENTAGE'" height="46px" type="number" radius="8px" :maxLength="100" @info="backReduction"/>

              <div class="text-83 text-c14 mt-4 mb-2">Début</div>
              <datePicker height="46px" radius="8px" @info="backStart"/>

              <div class="text-83 text-c14 mt-4 mb-2">Expiration</div>
              <datePicker height="46px" radius="8px" @info="backEnd"/>

              <div class="text-83 text-c14 mt-4 mb-2" v-if="sfdData.length > 0">Clientèle cible</div>
              <multiselect v-if="sfdData.length > 0" :option="cible" height="46px" radius="8px" @info="backSfd"/>
            </div>

            <div v-if="error !== null" class="text-red text-center text-c14 mt-6">{{error}}</div>
            <div class="mt-12 flex justify-center">

              <div class="w-full">
                <buton
                    :charge="charge"
                    label="Enregistrer"
                    size="16px"
                    weight="600"
                    height="51px"
                    radius="5px"
                    background="#312879"
                    @info="save"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import copy from '../../assets/icons/ic-copy.svg'
import fermer from '../../assets/icons/fermer.svg'
import inputo from '../helper/form/input'
import datePicker from '../helper/form/datePicker'
import multiselect from '../helper/form/multiselect'
import apiroutes from '../../router/api-routes'
import http from '../../plugins/https'

export default {
  name: 'Success',
  components: { PopupBase, buton, inputo, datePicker, multiselect },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    coupon: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      wantToMakeOffer: false,
      icons: {
        copy,
        fermer
      },
      error: null,
      position: -1,
      cible: ['Tous les utilisateurs'],
      sfdData: [],
      response: {
        cibleId: 'USER'
      },
      couponGenerate: null,
      charge: true,
      type: ['Pourcentage (%)', 'Montant'],
      valueType: 'Montant'
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
    this.allSfd()
  },

  methods: {
    onCopy (a) {
      console.log(a)
    },

    backType(a) {
      if (a === 'Pourcentage (%)') {
        this.response.type = 'PERCENTAGE'
      } else {
        this.response.type = 'AMOUNT'
      }
    },

    onError (a) {
      console.log(a)
    },

    fermer () {
      this.$emit('oga', false)
    },

    success () {
      this.$emit('oga', true)
    },

    backReduction (r) {
      this.response.reduction = r
    },

    backStart (s) {
      this.response.startDate = s
    },

    backEnd(e) {
      this.response.expireDate = e
    },

    backSfd (s) {
      for (let i in this.sfdData) {
        if (this.sfdData[i].sfd.name === s) {
          this.response.cibleId = this.sfdData[i].sfd.id
        }
      }
    },

    save () {
      // console.log(this.response)
      if (this.response.startDate && this.response.expireDate && this.response.reduction) {
        this.createCoupon()
      } else {
        this.error = 'Veuillez remplir les champs et ressayer'
      }
    },

    createCoupon () {
      this.charge = true
      http.post(apiroutes.baseURL + apiroutes.generateCoupon, this.response)
          .then(response => {
            // console.log(response)
            this.couponGenerate = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
           console.log(error)
          })
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    },

    allSfd () {
      http.get(apiroutes.baseURL + apiroutes.allSfd)
          .then(response => {
            this.sfdData = response
            // console.log(response)
            for (let i in response) {
              this.cible.push(response[i].sfd.name)
            }
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            // console.log(error)
          })
    }

  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 42%;
    }
    .agence {
      background: #EFF3FE;
      border: 1px solid #DDDDDD;
      box-sizing: border-box;
      border-radius: 8.03241px;
      height: 79.52px;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: $white;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }

    .tete {
      background-color: #F3F7FA;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
