<template>
  <div class="">
    <div class="w-full flex text-c16 font-c4 text-left items-center item cursor-pointer">
      <div class="w-1/6 flex items-center font-c6">
        <div>{{ item.data.payementCode }}</div>
      </div>

      <div class="w-1/6 ml-4 flex items-center">
        {{ item.data.reduction }}
      </div>

      <div class="w-1/6 ml-4">
       {{ conversion(item.data.startDate) }}
      </div>

      <div class="w-1/6 ml-4">
        {{ conversion(item.data.expireDate) }}
      </div>

      <div class="w-1/6 ml-4">
        <span v-if="item.client === 'ALLUSER'">CCIB</span>
        <span v-if="item.client !== 'ALLUSER'">{{ item.client.name }}</span>
      </div>

      <div class="w-1/6 ml-4">
        {{ item.data.isUsed.length }}
      </div>

    </div>
  </div>
</template>

<script>
import user from '../../../assets/icons/ic-clientAv.svg'
import copy from '../../../assets/icons/ic-copy.svg'

export default {
  name: "Index",
  components: {
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      icons: {
        user,
        copy
      },
    }
  },
  methods: {
    conversion (index) {
      return new Date(index).toLocaleDateString()
    }
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
</style>
