<template>
  <div class="p-6 pr-6 all">
    <coupons :activation="activeCoupon" v-if="activeCoupon" @oga="backCoupons"/>
    <div>
      <div
        class="flex items-center"
      >
        <div class="w-1/2 flex text-left text-c22 items-center font-c8">
          Coupons
        </div>

        <div class="w-1/2 flex justify-end">
          <div class="w-1/4 mr-4">
            <multiselect :option="periode" value-t="Période" height="51px" iconHeight="20" :icon="icons.calendar"/>
          </div>

          <buton
              label="Générer un code coupon"
              class="w-3/5"
              size="18px"
              rounded="6px"
              height="51px"
              weight="700"
              background="#312879"
              @info="activeCoupon = true"
          />
        </div>
      </div>

      <div class="mt-6" v-if="!reloadDetail">
        <global-view
          :search="recherche"
          :filtrage="valueCategorie"
          @filt="filtrage = null"
        />
      </div>
    </div>
  </div>
</template>

<script>
import globalView from '../../component/cdevs/coupons/globalData'
import buton from '../../component/helper/add/button'
import multiselect from '../../component/helper/form/multiselect'
import calendar from '../../assets/icons/calendar.svg'
import coupons from '../../component/popup/coupon'

export default {
  name: "Index",

  components: {
    globalView,
    buton,
    multiselect,
    coupons
  },

  data () {
    return {
      icons: {
        calendar
      },
      periode: ['Année en cours'],
      activeCoupon: false,
      reloadDetail: false
    }
  },

  created () {
  },

  methods: {
    backCoupons (c) {
      if (c) {
        this.reloadDetail = true
        let vm = this
        setTimeout(function(){
          vm.reloadDetail = false
        }, 500)
      }

      this.activeCoupon = false
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.all{
  min-height: 100vh;
}
</style>
